/** ***********************************************************************************
 * Lemuria layout design tokens
 *********************************************************************************** */

:root {
  /**
   * Inline breakpoints
   */

  --lm-global--breakpoints--inline-xs: 35.5rem;  /** Extrasmall: 568px */
  --lm-global--breakpoints--inline-sm: 50rem;  /** Small: 800px */
  --lm-global--breakpoints--inline-md: 64rem;  /** Medium: 1024px */
}
