/** ***********************************************************************************
 * Color design tokens
 *********************************************************************************** */

:root {
  /**
   * Primary
   */

  /** Sky blue: #b3eeff */
  --lm-global--palette-sky-blue--100: rgba(179, 238, 255, 1);
  --lm-global--palette-sky-blue--50: rgba(179, 238, 255, .5);
  --lm-global--palette-sky-blue--18: rgba(179, 238, 255, .18);
  --lm-global--palette-sky-blue: var(--lm-global--palette-sky-blue--100);

  /** White: #fff */
  --lm-global--palette-white--40: rgba(255, 255, 255, .4);
  --lm-global--palette-white--49: rgba(255, 255, 255, .49);
  --lm-global--palette-white--70: rgba(255, 255, 255, .7);
  --lm-global--palette-white--100: rgba(255, 255, 255, 1);
  --lm-global--palette-white: var(--lm-global--palette-white--100);

  /** Black: #000 */
  --lm-global--palette-black--100: rgba(0, 0, 0, 1);
  --lm-global--palette-black: var(--lm-global--palette-black--100);

  /** Dark blue: #002E52 */
  --lm-global--palette-dark-blue--100: rgba(0, 46, 82, 1);
  --lm-global--palette-dark-blue--65: rgba(0, 46, 82, .65);
  --lm-global--palette-dark-blue--31: rgba(0, 46, 82, .31);
  --lm-global--palette-dark-blue: var(--lm-global--palette-dark-blue--100);

  /** Prussian blue (blu_mapps): #003762 */
  --lm-global--palette-prussian-blue--100: rgba(0, 55, 98, 1);
  --lm-global--palette-prussian-blue: var(--lm-global--palette-prussian-blue--100);

  /** Supreme grey (disabled): #87949e */
  --lm-global--palette-supreme-grey--100: rgba(135, 148, 158, 1);
  --lm-global--palette-supreme-grey: var(--lm-global--palette-supreme-grey--100);

  /** Dark grey: #221f20 */
  --lm-global--palette-dark-grey--100: rgba(34, 31, 32, 1);
  --lm-global--palette-dark-grey: var(--lm-global--palette-dark-grey--100);

  /** Indigo black: #002e52 */
  --lm-global--palette-indigo-black--100: rgba(0, 46, 82, 1);
  --lm-global--palette-indigo-black: var(--lm-global--palette-indigo-black--100);


  /**
   * Accent
   */

  /** Bright light blue: #52d7e3 */
  --lm-global--palette-bright-light-blue--100: rgba(82, 215, 227, 1);
  --lm-global--palette-bright-light-blue--80: rgba(82, 215, 227, .8);
  --lm-global--palette-bright-light-blue--47: rgba(82, 215, 227, .47);
  --lm-global--palette-bright-light-blue--40: rgba(82, 215, 227, .4);
  --lm-global--palette-bright-light-blue--30: rgba(82, 215, 227, .3);
  --lm-global--palette-bright-light-blue: var(--lm-global--palette-bright-light-blue--100);

  /** Light blue: #00538f */
  --lm-global--palette-light-blue--100: rgba(0, 83, 143, 1);
  --lm-global--palette-light-blue--80: rgba(0, 83, 143, .8);
  --lm-global--palette-light-blue--30: rgba(0, 83, 143, .3);
  --lm-global--palette-light-blue: var(--lm-global--palette-light-blue--100);

  /** Aggressive aqua: #00f9ff */
  --lm-global--palette-aqua--100: rgba(0, 249, 255, 1);
  --lm-global--palette-aqua: var(--lm-global--palette-aqua--100);

  /** Winter's day: #dbf7ff */
  --lm-global--palette-winter--100: rgba(219, 247, 255, 1);
  --lm-global--palette-winter: var(--lm-global--palette-winter--100);

  /** Red door: #ae0000 */
  --lm-global--palette-red--100: rgba(174, 0, 0, 1);
  --lm-global--palette-red: var(--lm-global--palette-red--100);

  /** Christmas green: #378907 */
  --lm-global--palette-green--100: rgba(55, 137, 7, 1);
  --lm-global--palette-green: var(--lm-global--palette-green--100);


  /**
   * Secondary
   */

  /** Dusty blue: #afd3e5 */
  --lm-global--palette-dusty-blue--100: rgba(175, 211, 229, 1);
  --lm-global--palette-dusty-blue--51: rgba(175, 211, 229, .51);
  --lm-global--palette-dusty-blue--40: rgba(175, 211, 229, .4);
  --lm-global--palette-dusty-blue: var(--lm-global--palette-dusty-blue--100);


  /* IMPORTS FROM PB FOR CUSTOM HEADER */
  --lm-global--palette-dusty-blue--100-r: 175;
  --lm-global--palette-dusty-blue--100-g: 211;
  --lm-global--palette-dusty-blue--100-b: 229;
  --lm-global--palette-dusty-blue--40-a: .4;
  --lm-global--palette-dusty-blue--40-r: calc(var(--lm-global--palette-dusty-blue--100-r) + (255 - var(--lm-global--palette-dusty-blue--100-r)) * (1 - var(--lm-global--palette-dusty-blue--40-a)));
  --lm-global--palette-dusty-blue--40-g: calc(var(--lm-global--palette-dusty-blue--100-g) + (255 - var(--lm-global--palette-dusty-blue--100-g)) * (1 - var(--lm-global--palette-dusty-blue--40-a)));
  --lm-global--palette-dusty-blue--40-b: calc(var(--lm-global--palette-dusty-blue--100-b) + (255 - var(--lm-global--palette-dusty-blue--100-b)) * (1 - var(--lm-global--palette-dusty-blue--40-a)));
  --lm-global--palette-dusty-blue--40:  rgba(var(--lm-global--palette-dusty-blue--40-r), var(--lm-global--palette-dusty-blue--40-g), var(--lm-global--palette-dusty-blue--40-b), 1);

  --lm-global--palette-dark-grey--49: rgba(34, 31, 32, .49);
  --lm-global--palette-light-blue--45: rgba(0, 83, 143, .45);
  /* END IMPORTS FOR CUSTOM HEADER */


  /** Blue: #003762 */
  --lm-global--palette-blue--100: rgba(0, 55, 98, 1);
  --lm-global--palette-blue: var(--lm-global--palette-blue--100);


  /**
   * Gradient colors
   */

  --lm-global--palette-gradient--button: linear-gradient(
    -63.435deg,
    rgba(0, 139, 194, 1) 0%,  /* FIXME: what color is this? */
    var(--lm-global--palette-light-blue) 100%
  );
  --lm-global--palette-gradient--page: linear-gradient(
    270deg,
    var(--lm-global--palette-dusty-blue) 0%,
    var(--lm-global--palette-light-blue) 71%,
    var(--lm-global--palette-dark-blue) 100%
  );


  /**
   * Orbit colors
   */

  --lm-global--palette-orbite: rgb(0, 249, 255);

  /**
   * Colors without alpha channel
   */

  --lm-global--palette-light-blue--100-r: 0;
  --lm-global--palette-light-blue--100-g: 83;
  --lm-global--palette-light-blue--100-b: 143;
  --lm-global--palette-light-blue--26-a: .26;
  --lm-global--palette-light-blue--26-r: calc(var(--lm-global--palette-light-blue--100-r) + (255 - var(--lm-global--palette-light-blue--100-r)) * (1 - var(--lm-global--palette-light-blue--26-a)));
  --lm-global--palette-light-blue--26-g: calc(var(--lm-global--palette-light-blue--100-g) + (255 - var(--lm-global--palette-light-blue--100-g)) * (1 - var(--lm-global--palette-light-blue--26-a)));
  --lm-global--palette-light-blue--26-b: calc(var(--lm-global--palette-light-blue--100-b) + (255 - var(--lm-global--palette-light-blue--100-b)) * (1 - var(--lm-global--palette-light-blue--26-a)));
  --lm-global--palette-light-blue--26:  rgba(var(--lm-global--palette-light-blue--26-r), var(--lm-global--palette-light-blue--26-g), var(--lm-global--palette-light-blue--26-b), 1);
}
