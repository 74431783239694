/** ***********************************************************************************
 * Lemuria typography design tokens
 *********************************************************************************** */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-thin-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-light-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 200 300;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-medium-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-bold-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 600 700;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-extrabold-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 800 900;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-italic-webfont.woff2") format("woff2");
  font-style: italic;
  font-weight: 400;
}

:root {
  /**
   * Font families
   */

  --lm-global--FontFamily-default: "Larsseit", "Roboto", sans-serif;


  /**
   * Font weights
   */

  --lm-global--FontWeight-light:   300;
  --lm-global--FontWeight-normal:  400;
  --lm-global--FontWeight-medium:  500;
  --lm-global--FontWeight-bold:    700;
  --lm-global--FontWeight-regular: var(--lm-global--FontWeight-normal);


  /**
   * Font sizes
   */

  --lm-global--FontSize-title--sm:   .938rem;     /* Small: 15px */
  --lm-global--FontSize-title--md:      1rem;     /* Medium: 16px */
  --lm-global--FontSize-title--lg:   1.25rem;     /* Large: 20px */
  --lm-global--FontSize-title--xl:  1.563rem;     /* Extra large: 25px */
  --lm-global--FontSize-title--2xl: 1.875rem;     /* Extra extra large: 30px */
  --lm-global--FontSize-title--3xl: 2.812rem;     /* Extra extra extra large: 45px */
  --lm-global--FontSize-title--hg:      5rem;      /* Humongous: 80px */

  --lm-global--FontSize-paragraph--sm:     1rem;  /* Small: 16px */
  --lm-global--FontSize-paragraph--md: 1.125rem;  /* Medium: 18px */
  --lm-global--FontSize-paragraph--lg: 1.125rem;  /* Medium: 22px */

  --lm-global--FontSize-label--xs:   .75rem;      /* Extra Small: 12px */
  --lm-global--FontSize-label--sm:  .875rem;      /* Small: 14px */
  --lm-global--FontSize-label--md:     1rem;      /* Medium: 16px */
  --lm-global--FontSize-label--lg: 1.375rem;      /* Medium: 22px */


  /**
   * Text styles
   */

  --lm-global--Font-title--sm-medium: var(--lm-global--FontWeight-medium) var(--lm-global--FontSize-title--sm) var(--lm-global--FontFamily-default);
  --lm-global--Font-title--md-medium: var(--lm-global--FontWeight-medium) var(--lm-global--FontSize-title--md) var(--lm-global--FontFamily-default);
  --lm-global--Font-title--md-regular: var(--lm-global--FontWeight-regular) var(--lm-global--FontSize-title--md) var(--lm-global--FontFamily-default);
  --lm-global--Font-title--lg-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-title--lg) var(--lm-global--FontFamily-default);
  --lm-global--Font-title--xl-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-title--xl) var(--lm-global--FontFamily-default);
  --lm-global--Font-title--2xl-medium: var(--lm-global--FontWeight-medium) var(--lm-global--FontSize-title--2xl) var(--lm-global--FontFamily-default);
  --lm-global--Font-title--2xl-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-title--2xl) var(--lm-global--FontFamily-default);
  --lm-global--Font-title--3xl-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-title--3xl) var(--lm-global--FontFamily-default);
  --lm-global--Font-title--hg-medium: var(--lm-global--FontWeight-medium) var(--lm-global--FontSize-title--hg) var(--lm-global--FontFamily-default);

  --lm-global--Font-paragraph--sm-light: var(--lm-global--FontWeight-light) var(--lm-global--FontSize-paragraph--sm) var(--lm-global--FontFamily-default);
  --lm-global--Font-paragraph--md-regular: var(--lm-global--FontWeight-regular) var(--lm-global--FontSize-paragraph--md) var(--lm-global--FontFamily-default);
  --lm-global--Font-paragraph--md-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-paragraph--md) var(--lm-global--FontFamily-default);

  --lm-global--Font-label--xs-medium: var(--lm-global--FontWeight-medium) var(--lm-global--FontSize-label--xs) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--sm-light: var(--lm-global--FontWeight-light) var(--lm-global--FontSize-label--sm) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--sm-regular: var(--lm-global--FontWeight-regular) var(--lm-global--FontSize-label--sm) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--sm-medium: var(--lm-global--FontWeight-medium) var(--lm-global--FontSize-label--sm) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--sm-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-label--sm) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--md-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-label--md) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--lg-medium: var(--lm-global--FontWeight-medium) var(--lm-global--FontSize-label--lg) var(--lm-global--FontFamily-default);
}
